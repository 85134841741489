<template>
  <div class="container-fluid mt-3 mb-3">
    <CCard>
      <CRow class="pl-3 pr-3">
        <CCol cols="12" :md="9">
          <form v-on:submit.prevent="filterMeter(filter)">
            <CRow>
              <CCol sm="4" class="pr-0">
                <select
                    name="projects"
                    v-model="filter.column"
                    class="form-control"
                >
                  <option value="">Select Column</option>
                  <option
                      v-for="column in columns"
                      :key="column.id"
                      :value="column.id"
                  >
                    {{ column.name }}
                  </option>
                </select>
              </CCol>
              <CCol sm="4" class="pr-0">
                <CInput
                    v-model="filter.keyword"
                    placeholder="Search..."
                />
              </CCol>

              <CCol sm="2">
                <button class="btn btn-success w-100">Search</button>
              </CCol>
            </CRow>
          </form>
        </CCol>
        <CCol class="text-right" cols="12" :md="3">
          <button
            v-if="checkUsrPermissions(['Meter_all', 'Meter_create'])"
            class="btn btn-sm common-btn-bg btn-primary"
            @click="createMeter"
          >
            + Create Meter
          </button>
          &nbsp; &nbsp;
          <router-link
            v-if="checkUsrPermissions(['Meter_all', 'Meter_create'])"
            class="btn btn-sm btn-info d-none"
            :to="{ name: 'ScanAndStoreMeter' }"
            >+ Scan and Add Meter</router-link
          >
        </CCol>
      </CRow>

      <CRow>
        <CCol class="partner-custom-table-design">
          <div class="table-responsive p-3">
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th class="font-weight-bold">SL</th>
                  <th class="font-weight-bold">Project</th>
                  <th class="font-weight-bold">QR Code</th>
                  <th class="font-weight-bold">Size of Conn.</th>
                  <th scope="col">Meter Status</th>
                  <th scope="col">Meter Type</th>
                  <th scope="col">Install date</th>
                  <th class="font-weight-bold">Device EUI</th>
                  <th class="font-weight-bold">Meter No</th>
                  <th class="font-weight-bold">
                    Current <br />
                    Consumption
                  </th>
                  <th class="font-weight-bold">
                    Last <br />
                    Reading Time
                  </th>
                  <th
                    class="font-weight-bold"
                    v-if="
                      checkUsrPermissions([
                        'Meter_all',
                        'Meter_show',
                        'Meter_consumption',
                        'MeterError_log',
                        'MeterError_edit',
                      ])
                    "
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody v-if="meters.length">
                <tr v-for="(meter, index) in meters" :key="meter.id">
                  <td scope="row">{{ meta.from + index }}</td>
                  <td>{{ meter.project? meter.project.name : "N/A" }}</td>
                  <td>
                    <img
                      v-if="meter.qr_code_url"
                      :src="meter.qr_code_url"
                      class="qrcode"
                      alt="qrcode"
                    />
                  </td>

                  <td>
                    {{ meter.connection_size }}
                  </td>
                  <td scope="row">
                    {{ meter.meter_status }}
                  </td>
                  <td scope="row">
                    {{ meter.meter_type }}
                  </td>
                  <td scope="row">
                    {{ meter.installing_date | dateFormat }}
                  </td>
                    <td>
                    {{ meter.dev_eui || "N/A" }}
                  </td>
                  <td scope="row"><router-link :to="'/meters/' + meter.id">{{ meter.meter_number }}</router-link></td>
                  <td>{{ meter.current_consumption_unit }}</td>
                  <td>
                    {{ meter.reading_date_time && meter.reading_date_time | dateTimeFormat }}
                  </td>
                  <td
                    v-if="
                      checkUsrPermissions([
                        'Meter_all',
                        'Meter_show',
                        'Meter_consumption',
                        'MeterError_log',
                        'MeterError_edit',
                      ])
                    "
                  >
                    <MeterInventoryPopOver @onEditClicked="editMeter(meter)" :id="meter.id" :meter="meter" />
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="8">
                    <h5 class="text-center">Data Not Available.</h5>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CCol>
      </CRow>

      <CModal
        :closeOnBackdrop="false"
        size="lg"
        title="Add Meter"
        color="primary"
        class="hide-footer custom-close-button"
        :show.sync="createMeterModal"
      >
        <CreateMeter
          @closeCreateMeterModal="closeCreateMeterModal"
          :constants="constants"
        ></CreateMeter>
      </CModal>

    <CModal
      v-if="editMeterModal"
      :closeOnBackdrop="false"
      size="lg"
      title="Edit Meter"
      color="primary"
      class="hide-footer custom-close-button"
      :show.sync="editMeterModal"
    >
      <EditMeter
        :meterId="meterId"
        :meter="meter"
        :constants="constants"
        @onEditedMeter="dismissEditMeterModal"
      ></EditMeter>
    </CModal>

      <div class="overflow-auto" v-if="meters.length">
        <!-- Use text in props -->
        <CPagination
          v-if="meters.length"
          align="center"
          :pages="rows"
          :active-page.sync="filter.currentPage"
          @update:activePage="handlePageChange"
        />
      </div>
    </CCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CreateMeter from "./CreateMeter.vue";
import EditMeter from "./EditMeter";
import MeterInventoryPopOver from "../meter/partial/MeterInventoryPopOver";
import axios from "axios";
export default {
  name: "InventoryMeters",
  data: () => {
    return {
      columns: [
        {id: 'hardware_serial_no', name: 'HARDWARE SERIAL NO'},
        {id: 'meter_number', name: 'METER NO'},
        {id: 'batch_no', name: 'BATCH NO'},
        {id: 'dev_eui', name: 'DEV EUI'},
        {id: 'dn', name: 'DN'},
      ],
      createMeterModal: false,
      editMeterModal: false,
      meter:{},
      filter: {
        column: "",
        keyword: "",
        hardware_serial_no: "",
        currentPage: 1,
        dev_eui: "",
        dn: "",
        show: "all",
      },
      deletingState: false,
      loadingState: false,
      // role: {},
      serverErrors: [],
      showDismissibleAlert: false,
      meterId:''
      // rows: 1,
      // perPage: 10,
      // currentPage: 1
    };
  },
  components: {
    CreateMeter,
    EditMeter,
    MeterInventoryPopOver,
  },
  computed: {
    ...mapGetters("Constant", ["constants"]),
    ...mapGetters("Meter", ["meters", "rows", "perPage", "isLoading", "meta"]),
  },
  methods: {
    filterMeter(search) {
      if (search) {
        search.currentPage = 1;
        this.$store.dispatch("Meter/getMeters", search);
      }
    },
    createMeter() {
      this.createMeterModal = true;
    },

    closeCreateMeterModal() {
      this.createMeterModal = false;
    },

    dismissEditMeterModal() {
      this.editMeterModal = false;
      this.meter = {};
    },

    dateFormat(date) {
      return moment(date).format("Do MMM, YYYY h:mm a");
    },
    handlePageChange(value) {
      this.filter.currentPage = value;
      this.$store.dispatch("Meter/getMeters", this.filter);
    },
    editMeter(meter) {
      axios.get(`/admin/meters/${meter.id}?isEdit=${true}`).then((res) => {
        this.meter = res.data.data;
        this.meterId = meter.id;
        this.editMeterModal = true;
      }).catch((err) => {
        console.log(err);
      });
    },
  },
  mounted() {
    this.$store.dispatch("Constant/getConstants", 'meter_sizes,meter_type,meter_status');
    this.$store.dispatch("Meter/getMeters", this.filter);
  },
};
</script>

<style scoped>
.card {
    border: none;
    padding: 15px 15px 0;
}
tbody:before {
  content: "@";
  display: block;
  line-height: 30px;
  text-indent: -99999px;
}
.hide-footer >>> footer {
  display: none;
}
.custom-close-button >>> button {
  color: aliceblue;
}
</style>

<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }
    tbody {
      font-size: 13px;
      tr {
        color: #000;
        td {
          padding: 0.75rem 0 0.75rem 0.75rem;
          button {
            font-size: 13px;
          }
        }
        td:nth-child(1) {
          color: #5e76e7;
        }
        td:nth-child(2) {
          font-weight: 500 !important;
        }
      }
    }
  }
}
</style>

