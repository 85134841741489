<template>
  <div>
    <div class="popover__wrapper">
      <a href="javascript:void(0)">
        <svg
          data-v-35c89fa8=""
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-settings"
        >
          <circle data-v-35c89fa8="" cx="12" cy="12" r="3"></circle>
          <path
            data-v-35c89fa8=""
            d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"
          ></path>
        </svg>
      </a>

      <div class="popover__content">
        <ul class="p-0">
          <li v-if="checkUsrPermissions(['Meter_all', 'Meter_show'])">
            <router-link :to="'/meters/' + id">View</router-link>
            &nbsp;
          </li>
          <li v-if="checkUsrPermissions(['Meter_all', 'Meter_consumption'])">
            <router-link :to="'/meters/' + id + '/consumptions'"
              >Consumptions</router-link
            >
            &nbsp;
          </li>
          <li v-if="checkUsrPermissions(['Meter_all', 'MeterError_log'])">
            <router-link :to="'/meters/' + id + '/errors'"
              >Error Logs</router-link
            >
            &nbsp;
          </li>
          <li v-if="checkUsrPermissions(['Meter_all', 'MeterError_edit'])">
            <span @click="editMeter(meter)">Edit</span>
            &nbsp;
          </li>
        </ul>
      </div>
    </div>
    <!-- <CModal
      v-if="editMeterModal"
      :closeOnBackdrop="false"
      size="lg"
      title="Edit Meter"
      color="primary"
      class="hide-footer custom-close-button"
      :show.sync="editMeterModal"
    >
      <EditMeter
        :meterId="meterId"
        :meter="meter"
        @onEditedMeter="dismissEditMeterModal"
      ></EditMeter>
    </CModal> -->
  </div>
</template>

<script>
// import EditMeter from "../../inventory/EditMeter";
export default {
  // components: {
  //   EditMeter,
  // },
  data: () => {
    return {
      // editMeterModal: false,
      meterId: "",
    };
  },
  props: ["id", "meter"],
  methods: {
    // dismissEditMeterModal() {
    //   this.editMeterModal = false;
    // },
    editMeter(meter) {
      this.$emit('onEditClicked', meter);
      // this.editMeterModal = true;
    },
  },
};
</script>

<style scoped>
.card {
    border: none;
    padding: 15px 15px 0;
}
tbody:before {
  content: "@";
  display: block;
  line-height: 30px;
  text-indent: -99999px;
}
.hide-footer >>> footer {
  display: none;
}
.custom-close-button >>> button {
  color: aliceblue;
}
</style>

<style lang="scss" scoped>
div {
  .popover__wrapper {
    position: absolute;

    display: inline-block;
    a {
      color: #000;
      text-decoration: none;
    }
  }
  .popover__content {
    opacity: 1;
    visibility: hidden;
    position: absolute;
    left: -150px;
    transform: translate(0, 10px);
    background-color: #fff;
    padding: 1.5rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    width: auto;
    ul li {
      color: #000;
      padding: 10px 8px 10px 15px;
      font-size: 15px;
      border-bottom: 1px solid rgba(204, 204, 204, 0.4);
      line-height: 1;
      list-style: none;
      a {
        color: #000;
        text-decoration: none;
      }
      span {
        cursor: pointer;
      }
    }
  }

  .popover__content:before {
    position: absolute;
    z-index: -1;
    content: "";
    right: calc(50% - 10px);
    top: -8px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent white transparent;
    transition-duration: 0.3s;

    transition-property: transform;
  }
  .popover__wrapper:hover .popover__content {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 10;
    color: white;
    opacity: 1;
    visibility: visible;
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
    left: 50%;
    transform: translate(-50%, 5px);
  }
  .popover__message {
    text-align: center;
  }
}
</style>
