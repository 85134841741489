<template>
  <CRow>
    <CCol>
      <div v-if="serverErrors.length">
        <CAlert
          v-model="showDismissibleAlert"
          color="danger"
          closeButton
          v-for="err in serverErrors"
          :key="err"
        >
          {{ err }}
        </CAlert>
      </div>
      <validation-observer ref="formValidator">
        <form v-on:submit.prevent="addMeter">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group" v-if="projectsList">
                <label>Projects</label>
                <multiselect
                    v-model="meter.project"
                    :options="projectsList"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Select Project"
                    label="name"
                    track-by="id"
                    :preselect-first="false"
                >
                  <template
                      slot="selection"
                      slot-scope="{ values, search, isOpen }"
                  ><span
                      class="multiselect__single"
                      v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }} options selected</span
                  ></template
                  >
                </multiselect>
              </div>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                name="Batch No"
                :rules="{ required: true }"
                v-slot="{ errors }"
                 vid="batch_no"
              >
                <div class="form-group">
                  <label>Batch No.</label>
                  <CInput
                    type="text"
                    v-model="meter.batch_no"
                    placeholder="Enter Batch No."
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                    required
                  />
                </div>
                <p class="text-danger mt-0 pt-0">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                name="Category"
                :rules="{ required: true }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>Category</label>
                  <select
                    class="form-control"
                    v-model="meter.category_id"
                    required
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                  >
                    <option value="">Select Category</option>
                    <option v-bind:value="1">Water Meter</option>
                  </select>
                </div>
                <p class="text-danger mt-0 pt-0">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                name="Size of Conn."
                :rules="{ required: true }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>Size of Conn.</label>
                  <select
                      :class="errors.length ? 'is-invalid' : ''"
                      name="connection_type"
                      v-model="meter.connection_size"
                      class="form-control"
                      required
                  >
                    <option value="">Select</option>
                    <option v-for="(meter_size, key) in constants.meter_sizes" :value="key">{{ meter_size }}</option>
                  </select>
                </div>
                <p class="text-danger mt-0 pt-0">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
          </div>

          <div class="row">
            <!-- <div class="col-md-6">
            <div class="form-group">
              <label>Meter Number</label>
              <input
                type="text"
                class="form-control"
                v-model="meter.meter_number"
                placeholder="Enter meter number"
                required
              />
            </div>
          </div> -->
            <div class="col-md-6">
              <ValidationProvider
                vid="hardware_serial_no"
                name="Meter Serial No"
                :rules="{ required: true, min: 4 }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>Meter No.</label>
                  <CInput
                    type="text"
                    v-model="meter.hardware_serial_no"
                    placeholder="Enter No."
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                    required
                  >
                  </CInput>
                </div>
                <p class="text-danger mt-0 pt-0">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <ValidationProvider
                name="Brand Name"
                :rules="{ required: true }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>Brand Name</label>
                  <CInput
                    type="text"
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                    v-model="meter.brand_name"
                    placeholder="Enter Brand Name"
                    required
                  />
                </div>
                <p class="text-danger mt-0 pt-0">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <ValidationProvider
                name="Model Number"
                :rules="{ required: true }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>Model Number</label>
                  <CInput
                    type="text"
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                    v-model="meter.model_number"
                    placeholder="Model number"
                    required
                  />
                </div>
                <p class="text-danger mt-0 pt-0">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <ValidationProvider
                name="Manufacture Year"
                :rules="{ digits: 4 }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>Manufacture Year</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="meter.manufacture_year"
                    placeholder="Manufacture Year"
                  />
                  <p class="text-danger mt-0 pt-0">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>Expire Date</label>
                <el-date-picker
                    required
                    class="w-100 p-0"
                    v-model="meter.expiry_date"
                    type="date"
                    placeholder="Expire Date"
                    :format="$datePickerFormat"
                    value-format="yyyy-MM-dd"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>IP Standard</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="meter.ip_standard_code"
                  placeholder="IP Standard"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>Max Pressure</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="meter.max_pressure"
                  placeholder="Max Pressure"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>Radio Frequency</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="meter.radio_frequency"
                  placeholder="Radio Frequency"
                />
              </div>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                  name="Meter Type"
                  :rules="{ required: false }"
                  v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>Meter Type</label>
                  <select
                      :class="errors.length ? 'is-invalid' : ''"
                      name="class"
                      v-model="meter.meter_type"
                      class="form-control"
                  >
                    <option value="">Select</option>
                    <option v-for="(meter_type, typeKey) in constants.meter_type" :value="typeKey">{{ meter_type }}</option>
                  </select>
                </div>
                <p class="text-danger mt-0 pt-0">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                  name="Meter Status"
                  :rules="{ required: false }"
                  v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>Meter Status</label>
                  <select
                      :class="errors.length ? 'is-invalid' : ''"
                      name="class"
                      v-model="meter.meter_status"
                      class="form-control"
                  >
                    <option value="">Select</option>
                    <option v-for="(meter_status, statusKey) in constants.meter_status" :value="statusKey">{{ meter_status }}</option>
                  </select>
                </div>
                <p class="text-danger mt-0 pt-0">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                name="Meter digit width"
                :rules="{ required: false }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>Meter digit width</label>
                  <CInput
                    type="text"
                    v-model="meter.meter_digit_width"
                    placeholder="Meter digit width"
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                  />
                </div>

                <p class="text-danger mt-0 pt-0">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                name="Device EUI"
                :rules="{ required: false }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>Device EUI</label>
                  <CInput
                    type="text"
                    v-model="meter.dev_eui"
                    placeholder="Device EUI"
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                  />
                </div>

                <p class="text-danger mt-0 pt-0">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
            <ValidationProvider
              name="Lora App Key"
              :rules="{ required: false }"
              v-slot="{ errors }"
            >
              <div class="form-group">
                <label>Lora App Key</label>
                <CInput
                  type="text"
                  v-model="meter.lora_app_key"
                  placeholder="Lora App Key"
                  :addInputClasses="errors.length ? 'is-invalid' : ''"
                />
              </div>

              <p class="text-danger mt-0 pt-0">{{ errors[0] }}</p>
             </ValidationProvider>
            </div>
          </div>

          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </validation-observer>
    </CCol>
  </CRow>
</template>

<script>
import Multiselect  from "vue-multiselect";
import {mapGetters} from "vuex";
import moment from "moment";
export default {
  name: "CreateMeter",
  props: [
    "constants"
  ],
  data: () => {
    return {
      meter: {
        project: {},
        category_id: 1,
        connection_size: "",
        meter_number: "",
        batch_no: "",
        hardware_serial_no: "",
        description: "",
        brand_name: "",
        model_number: "",
        manufacture_year: moment().format("YYYY"),
        expiry_date: "",
        ip_standard_code: "",
        max_pressure: "",
        radio_frequency: "",
        meter_type: "",
        meter_status: "",
        meter_digit_width: "",
        dev_eui: "",
        lora_app_key: "",
      },
      serverErrors: [],
      showDismissibleAlert: false,
      isLoading: false,
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    addMeter() {
      this.meter.project_id = this.meter.project ? this.meter .project.id : "";
      this.$store
        .dispatch("Meter/store", this.meter)
        .then(() => {
          this.showToast = true;
          this.$toastr.s(
            "success",
            "Meter successfully created.",
            "Meter Created"
          );
          this.$emit("closeCreateMeterModal");
          this.resetForm();
        })
        .catch((err) => {
          this.showDismissibleAlert = true;
          this.serverErrors = [];
          if (err.response.data.message) {
            this.serverErrors.push(err.response.data.message);
            this.$refs.formValidator.setErrors(err.response.data.errors);
          }
        });
    },
    resetForm(){
      this.meter = {
        project: {},
        category_id: 1,
        connection_size: "",
        meter_number: "",
        batch_no: "",
        hardware_serial_no: "",
        description: "",
        brand_name: "",
        model_number: "",
        manufacture_year: moment().format("YYYY"),
        expiry_date: Date,
        ip_standard_code: "",
        max_pressure: "",
        radio_frequency: "",
        dev_eui: "",
        lora_app_key: "",
      }
    }
  },
  mounted() {
    this.$store.dispatch("Projects/getProjectsList");
  },
  computed  : {
    ...mapGetters("Projects", ["projectsList"]),
  },
};
</script>

<style scoped lang="scss">
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
